import React, { useState } from "react";
import { useGetChargesReportInvoiceDownload } from "api/generated/customer/customer";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import { useBanParam } from "hooks/useBanParam";
import { pollFileUrl } from "helpers/filePolling";

interface DownloadChargesReportProps {
  invoiceId: number;
  downloadHook: typeof useGetChargesReportInvoiceDownload;
  label: string;
}

export const DownloadMenuItem = ({
  invoiceId,
  downloadHook,
  label,
}: DownloadChargesReportProps) => {
  const ban = useBanParam();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollingTimeoutError, setPollingTimeoutError] =
    useState<boolean>(false);
  const { error, isFetching, refetch } = downloadHook(ban, invoiceId, {
    query: {
      enabled: false,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data?.link) {
          setIsPolling(true);
          const fileUrl = data.link;

          pollFileUrl(fileUrl, {
            onSuccess: () => {
              setIsPolling(false);
              setPollingTimeoutError(false);
              window.location.href = fileUrl;
            },
            onError: () => {
              setIsPolling(false);
              setPollingTimeoutError(true);
            },
          });
        }
      },
    },
  });

  const handleDownload = () => {
    setPollingTimeoutError(false);
    refetch();
  };

  if (error) return <ErrorAlert message={error.message} />;
  if (pollingTimeoutError)
    return <ErrorAlert message="Timed out, try again." />;

  return (
    <MenuItem
      disabled={isPolling || isFetching}
      onClick={() => handleDownload()}
      sx={styles.root}
    >
      {isPolling || isFetching ? (
        <ListItemIcon>
          <CircularProgress size={24} />
        </ListItemIcon>
      ) : (
        <ListItemText>{label}</ListItemText>
      )}
    </MenuItem>
  );
};

const styles: SxStyleSheet = {
  root: { "&.Mui-disabled": { opacity: 1 } },
};
