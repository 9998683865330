import React, { useState } from "react";
import { CloudDownload } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useGetChargesReportDownload } from "api/generated/customer/customer";
import { GetChargesReportDownloadParams } from "api/generated/schemas";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import { useBanParam } from "hooks/useBanParam";
import { pollFileUrl } from "helpers/filePolling";
import { useGetBilledUsageReportDownload } from "api/generated/billed-usage/billed-usage";
import { useGetPhoneSummaryReportDownload } from "api/generated/phone-summary/phone-summary";
import { useGetPlexusCallReportDownload } from "api/generated/plexus-call-profile/plexus-call-profile";

interface DownloadReportProps extends GetChargesReportDownloadParams {
  reportDownloadHook:
    | typeof useGetChargesReportDownload
    | typeof useGetBilledUsageReportDownload
    | typeof useGetPhoneSummaryReportDownload
    | typeof useGetPlexusCallReportDownload;
}

export const DownloadReport = ({
  reportDownloadHook,
  periods,
  clis,
  start,
  end,
}: DownloadReportProps) => {
  const ban = useBanParam();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollingTimeoutError, setPollingTimeoutError] = useState(false);

  const { error, isFetching, refetch } = reportDownloadHook(
    ban,
    {
      periods,
      clis,
      end,
      start,
    },
    {
      query: {
        enabled: false,
        cacheTime: 0,
        onSuccess: (data) => {
          if (data?.link) {
            setIsPolling(true);
            const fileUrl = data.link;

            pollFileUrl(fileUrl, {
              onSuccess: () => {
                setIsPolling(false);
                setPollingTimeoutError(false);
                window.location.href = fileUrl;
              },
              onError: () => {
                setIsPolling(false);
                setPollingTimeoutError(true);
              },
            });
          }
        },
      },
    }
  );

  const handleDownload = () => {
    setPollingTimeoutError(false);
    refetch();
  };

  if (error) return <ErrorAlert message={error.message} />;
  if (pollingTimeoutError)
    return (
      <ErrorAlert message={"Failed to fetch the file. Please try again."} />
    );
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={
        isFetching ? <CircularProgress size={16} /> : <CloudDownload />
      }
      disabled={isPolling || isFetching}
      onClick={() => handleDownload()}
    >
      Download Report (CSV)
    </Button>
  );
};
