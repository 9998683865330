type PollFileOptions = {
  maxAttempts?: number;
  initialDelay?: number;
  timeoutDuration?: number;
  onSuccess: () => void;
  onError: () => void;
};

export const pollFileUrl = (
  fileUrl: string,
  options: PollFileOptions
): (() => void) => {
  const {
    maxAttempts = 10,
    initialDelay = 1000,
    timeoutDuration = 180000, // Default 3 minutes
    onSuccess,
    onError,
  } = options;

  const abortController = new AbortController();
  let attempt = 0;

  const checkFile = () => {
    fetch(fileUrl, { method: "GET", signal: abortController.signal })
      .then((response) => {
        if (response.ok) {
          onSuccess();
        } else {
          attempt++;
          if (attempt < maxAttempts) {
            setTimeout(checkFile, initialDelay * Math.pow(2, attempt));
          } else {
            onError();
          }
        }
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          attempt++;
          if (attempt < maxAttempts) {
            setTimeout(checkFile, initialDelay * Math.pow(2, attempt)); // Backoff delay
          } else {
            onError();
          }
        }
      });
  };

  const timeoutId = setTimeout(() => {
    abortController.abort();
    onError();
  }, timeoutDuration);

  checkFile();

  // Return a cleanup function
  return () => {
    abortController.abort();
    clearTimeout(timeoutId);
  };
};
